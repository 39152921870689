import React, { Fragment, Component } from "react";
import { Link } from "react-router-dom";
//import './Mediacard.css'

const CHI_API = "https://footage-media.com/profile_pics/";

class Card extends Component {
  render() {
    let imgSrc = `${CHI_API}${this.props.profile_pic}.jpg`,
      pageNumber = this.props.pageNumber.split("=")[1];
    console.log(this.props);
    return (
      <Fragment>
        <div className="bg-dark-gray dib br3 grow pa3 ma2 bw2 shadow-5 tc white-70">
          <Link to={`/${this.props.page}/=${pageNumber}`}>
            <img alt="chiguy" src={imgSrc}></img>
          </Link>
          <h3 className="pa1">{this.props.name}</h3>
        </div>
      </Fragment>
    );
  }
}

export default Card;
