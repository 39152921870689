import React, {Component, Fragment} from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

class Footer extends Component {
    render() {

        return (
            <Card bg="dark">
                <Card.Footer>
                <Button variant="outline-light" href="https://na01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fsupport.ccbill.com%2F&data=05%7C01%7C%7C4e3885c8fc86418f8ec508db7998a16c%7C84df9e7fe9f640afb435aaaaaaaaaaaa%7C1%7C0%7C638237468689382910%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=Wrm5nK4OXLp4DAjqyOhIpJG96nm61Dbrub9ISIQ4j7A%3D&reserved=0">CCBill Support</Button>
                <br />  
                <Card text="light" bg="dark">Chicago Footage Chicago, IL USA</Card>
                </Card.Footer>
            </Card>
        )
    }
}
export default Footer