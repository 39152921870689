import React, {Component} from "react";
import Mediacard from "./Mediacard";
import '../Css/Mediadex.css'

class Mediadex extends Component {

    constructor() {
        super()
        this.state = { data: []}
    }

    componentDidMount () {
        fetch('https://footage-media.com/json/CGFW_updates_info.json')
        .then(response => {
            return response.json()
        })
        .then (updateData => {
            console.log(updateData)
            this.setState({data: updateData})
        });
    }

    render() {
        return (
            <div className="Mediadex">
                <h1 className="section">Latest Updates</h1>
                <div className="Mediadex-cards">
                {this.state.data.map((media) => (
                    <Mediacard img={media.Image} prod={media.Product_Name} date={media.Date} 
                    type={media.Media_Type} link={media.URL} setNumber={media.Set}></Mediacard>
                ))}
                </div>
            </div>
        )
    }
}

export default Mediadex