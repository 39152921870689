import React, {Fragment, Component} from "react";
import '../Css/Mediadex.css'
import Card from './Card'

const Rosterdex = ({rosterData}) => {
    console.log(rosterData)
    return (
        <div className="Mediadex-cards">
            {
                rosterData.map((guy, i) => {
                    return <Card key={i} page='page' profile_pic={rosterData[i].CF_Image} 
                    pageNumber={rosterData[i].CF_Public_Page_}
                    name={rosterData[i].Model_Name}
                    ></Card>
                })
            }
        </div>
    )
}

export default Rosterdex;