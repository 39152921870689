import "bootstrap/dist/css/bootstrap.min.css";
import Card from "react-bootstrap/Card";
import Mediadex from "../Components/Mediadex";
import NaviContainer from "../Components/NaviContainer";
import Roundabout from "../Components/Roundabout";
import Footer from "../Components/Footer";

// Google Analytics
import ReactGA from "react-ga";
//import '../Css/main.css'

let Main = (props) => {
  //const TRACKING_ID = 'G-DM4W28MF1G '
  const TRACKING_ID = "UA-43768608-2";
  ReactGA.initialize(TRACKING_ID);
  ReactGA.pageview(document.location.pathname);
  return (
    <div className="App">
      <NaviContainer></NaviContainer>
      <br />
      <Card style={{ width: "85%" }} className="test">
        <Roundabout></Roundabout>
        <Mediadex></Mediadex>
      </Card>
      <br />
      <Footer></Footer>
    </div>
  );
};

export default Main;
