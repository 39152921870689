import React, { Component, Fragment } from "react";
import Card from "react-bootstrap/Card";
import Roundabout from "../../Components/Roundabout";
import Mediadex from "../Components/Mediadex";
import NaviContainer from "../Components/NaviContainer";
import Footer from "../../Components/Footer";
import ReactGA from "react-ga";

class MainMem extends Component {
  render() {
    //const TRACKING_ID = 'G-DM4W28MF1G '
    const TRACKING_ID = "UA-43768608-2";
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(document.location.pathname);

    return (
      <Fragment>
        <NaviContainer></NaviContainer>
        <br />
        <Card bg="black" text="white" style={{ width: "85%" }} className="test">
          <Roundabout></Roundabout>
          <Mediadex></Mediadex>
        </Card>
        <div className="tc">
          <Footer></Footer>
        </div>
      </Fragment>
    );
  }
}

export default MainMem;
