import React, {Component} from 'react';
import Nav from 'react-bootstrap/Nav';

class NaviLlinks extends Component {
    render() {
        if (this.props.text == 'CF Store') {
            return (
                <Nav.Link href='https://payhip.com/chicagofootage/' target="_blank" rel="noopener noreferrer" >{this.props.text}</Nav.Link>
            )
        } else if (this.props.text == 'Contact') {
            return (
                <Nav.Link href='https://chicago-footage.net/contact.php' target="_blank" rel="noopener noreferrer" >{this.props.text}</Nav.Link>
            )
        } else {
            return (
                <Nav.Link href={this.props.url}>{this.props.text}</Nav.Link>
            )
        }
    }
}
export default NaviLlinks