import React, { Fragment, Component } from "react";
import NaviContainer from "../Components/NaviContainer";
import { Card } from "react-bootstrap";
import Footer from "../Components/Footer";

// Google Analytics
//import ReactGA from 'react-ga4';
import ReactGA from "react-ga";

class Faq extends Component {
  render() {
    const TRACKING_ID = "G-DM4W28MF1G ";
    //const TRACKING_ID = 'UA-43768608-2'
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(document.location.pathname);

    return (
      <div>
        <NaviContainer></NaviContainer>
        <br />
        <Card style={{ width: "85%" }} className="test">
          <h1>Frequently Asked Questions</h1>
          <h3 className="question">
            Q) What happened to the other Chicago Footage Sites?
          </h3>
          <p>
            A) Chi Guys Feet, Chi Soles Exposed, and Chi Zillas have all been
            combined under 1 site: Chicago-Footage.net. Chi Guys Footwork has
            now become 'Footage-Media.com' and is not bundled with the other 3
            sites. Footage-Media.com is a trademark of Chicago Footage under
            which it is still owned and operated.
          </p>
          <br />
          <h3 className="question">Q) What happened to all the old content?</h3>
          <p>
            A) Due to increased file sizes and storage restraints, content from
            the previous site(s) has been archived to make room for newer
            content. Older conent will slowly be added over time where possible.
          </p>
          <br />
          <h3 className="question">Q) Are the videos downloadable?</h3>
          <p>
            A) All of the videos featured in the sites members area are
            downloadable unless specified as 'Store Exclusive'
          </p>
          <br />
          <h3 className="question">
            Q) I forgot my Username and Password, can you send me this
            information?
          </h3>
          <p>
            A) Lost user login information can be retrieved at:
            support.ccbill.com.
          </p>
          <br />
          <h3 className="question">
            Q) What payment processor is used for purchases in the Store?
          </h3>
          <p>
            A) PayPal and Stripe are the processors used for payment in the
            Chicago Footage Store.{" "}
          </p>
          <br />
          <h3 className="question">
            Q) How are digital purchases from the Chicago Footage Store
            delivered?
          </h3>
          <p>
            A) Videos and picture sets purchased from the Chicago Footage Store
            will be sent to the buyer instantly via download link through
            Payhip.com. The download link will be sent to the buyers email used
            during purchase. The download limit for each transaction is 2. If
            you experience errors that exhaust all download attempts, you may
            request to have the downloads reset. Buyers who make purchases via
            check through PayPal will receive the download link after the
            payment clears.{" "}
          </p>
          <br />
          <h3 className="question">
            Q) Can PayPal be used to purchase a membership?
          </h3>
          <p>
            A) CCBill is the only payment processor for website subscriptions
          </p>
          <br />
          <h3 className="question">Q) How do I cancel my membership?</h3>
          <p>A) To cancel your membership go to: support.ccbill.com.</p>
          <br />
          <h3 className="question">Q) Where does the content come from?</h3>
          <p>
            A) With the exception of collaborator submitted content, all content
            is original and produced by Chicago Footage and its sub sites. All
            models featured complete a waiver of consent prior to shooting for
            and being published on any Chicago Footage site.
          </p>
          <br />
          <h3 className="question">
            Q) If I have modeled before can I request to have my content
            removed?
          </h3>
          <p>
            A) Models and affiliates who wish to have their content removed may
            contact bruiser81@chicago-footage.net to discuss options for both
            parties.
          </p>
          <br />
        </Card>
        <br />
        <div className="tc">
          <Footer></Footer>
        </div>
      </div>
    );
  }
}
export default Faq;
