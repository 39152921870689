import React, {Fragment, Component} from "react";
import NaviLinksRoster from "../Components/NaviLinksRoster";
import Rosterdex from "../Components/Rosterdex";
import Footer from "../Components/Footer";

// Google Analytics
import ReactGA from 'react-ga';
//import '../Css/main.css'

class Roster extends Component {
    constructor() {
        super()
        this.state = {
            //chiGuy: rosterData,
            chiGuy: [],
            searchfield: ''
        }
    }

    componentDidMount () {
        fetch('https://footage-media.com/json/CGFW_roster_info.json')
        .then(response => {
            return response.json()
        })
        .then (rosterData => {
            console.log(rosterData)
            this.setState({chiGuy: rosterData})
        });
    }

    onSearchChange = (event) => {
        this.setState({searchfield: event.target.value})
    }

    render() {
        const TRACKING_ID = 'G-DM4W28MF1G '
        //const TRACKING_ID = 'UA-43768608-2'
        ReactGA.initialize(TRACKING_ID)
        ReactGA.pageview(document.location.pathname);

        console.log(this.state)
        const filterchiGuys = this.state.chiGuy.filter(chiGuy => {
            return chiGuy.Model_Name.toLowerCase().includes(this.state.searchfield.toLowerCase())
        })
        return(
            <div className="tc">
                <NaviLinksRoster onSearchChange={this.onSearchChange}></NaviLinksRoster>
                <Rosterdex rosterData={filterchiGuys}></Rosterdex>
                <Footer></Footer>
            </div>
        )
    }

}

export default Roster