import React, {Fragment, Component} from "react";
import Figure from 'react-bootstrap/Figure'
import ListGroup from 'react-bootstrap/ListGroup';

const CHI_API = 'https://chicago-footage.net/preview_thumbnails/'

class InfoCard extends Component {
    render() {
        let imgSrc = `${CHI_API}${this.props.modelInfo.CF_Image}.jpg`

        if (this.props.modelInfo.Collaborator === '1') {

            return (
                <Fragment>
                    <br></br>
                    <div className="ml">
                      <ListGroup horizontal>
                      <ListGroup.Item>
                            <Figure.Image
                            variant="bottom" src={imgSrc}
                            />
                      </ListGroup.Item>
        
                      <ListGroup.Item>
                            <Figure.Caption>
                                <h3>{`${this.props.modelInfo.Model_Name}`}</h3>
                                <p>{`collaborator`}</p>
                                <ul>
                                <li>{`Shoe Size: ${this.props.modelInfo.Shoe_Size}`}</li>
                                <li>{`Age: ${this.props.modelInfo.Age}`}</li>
                                <li>{`Height: ${this.props.modelInfo.Height}`}</li>
                                <li>{`Weight: ${this.props.modelInfo.Weight}`}</li>
                                <li>{`Nationality: ${this.props.modelInfo.Nationality}`}</li>
                                <li>{`Twitter: ${this.props.modelInfo.Twitter}`}</li>
                                <li>{`Instagram: ${this.props.modelInfo.Instagram}`}</li>
                                <li>{`TikTok: ${this.props.modelInfo.TikTok}`}</li>
                                <li>{`OnlyFans: ${this.props.modelInfo.OnlyFans}`}</li>
                                </ul>
                                </Figure.Caption>
                      </ListGroup.Item>
                    </ListGroup>
                    </div>
                </Fragment>
                )
        } else {
            return (
            <Fragment>
                <br></br>
                <div className="ml">
                  <ListGroup horizontal>
                  <ListGroup.Item>
                        <Figure.Image
                        variant="bottom" src={imgSrc}
                        />
                  </ListGroup.Item>
                  <ListGroup.Item>
                        <Figure.Caption>
                            <h3>{`${this.props.modelInfo.Model_Name}`}</h3>
                            <ul>
                            <li>{`Shoe Size: ${this.props.modelInfo.Shoe_Size}`}</li>
                            <li>{`Age: ${this.props.modelInfo.Age}`}</li>
                            <li>{`Height: ${this.props.modelInfo.Height}`}</li>
                            <li>{`Weight: ${this.props.modelInfo.Weight}`}</li>
                            <li>{`Nationality: ${this.props.modelInfo.Nationality}`}</li>
                            </ul>
                            </Figure.Caption>
                  </ListGroup.Item>
                </ListGroup>
                </div>
            </Fragment>
            )
        }

    }
}

export default InfoCard