import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Data from '../JSON/nav_links.json';
import NaviLlinks from '../Components/NaviLinks';

class NaviContainer extends Component {
    render() {
        return (
            <Navbar bg='dark' variant='dark' expand='lg' sticky='top'>
                <Container>
                    <Navbar.Toggle aria-controls='basic-navbar-nav'></Navbar.Toggle>
                    <Navbar.Collapse id='basic-navbar-nav'>
                        <Nav className='me-auto'>

                        {Data.map((link) => (
                            <NaviLlinks text={link.Label} url={link.Link}></NaviLlinks>
                        ))}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        )
    }
}

export default NaviContainer