import React, {Component, Fragment} from "react";
import { Link } from 'react-router-dom'

const CHI_API = 'https://chicago-footage.net/public_photos/sets/',
        CHI_API2 = 'https://chicago-footage.net/preview_thumbnails/'

class Mediacard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            setN: this.props.setNumber
        }
        this.clickFunction = this.clickFunction.bind(this)
    }

    clickFunction() {
        //this.setState({state: this.props.setNumber})
        //this.forceUpdate()
        //window.location.reload()
    }

    render() {
        let imgSrc = this.props.type === 'video' ? `${CHI_API2}${this.props.img}.jpg` : `${CHI_API}${this.props.img}`
        return (
            <div className="bg-dark-gray dib br3 grow pa3 ma2 bw2 shadow-5 tc white-70">
                <h1 className="Mediacard-title">{}</h1>
                <Link to={`/members_area/set=${this.state.setN}`}><img
                    onClick={this.clickFunction} 
                    className="updates" src={imgSrc}></img></Link>
                <h3 className="card-data">{this.props.prod}</h3>
                <div className="card-data">{this.props.date}</div>
                
            </div>
        )
    }
}

export default Mediacard