import React, { Component, Fragment } from "react";
import NaviContainer from "../Components/NaviContainer";
import Videoscreen from "../Components/Videoscreen";
import Featureing from "../Components/Featureing";
import Card from "react-bootstrap/Card";

// Google Analytics
//import ReactGA from 'react-ga4';
import ReactGA from "react-ga";

class PublicSet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSource: [],
    };
    this.changeState = this.changeState.bind(this);
    this.getSetInfo = this.getSetInfo.bind(this);
  }

  componentDidMount() {
    let arry = [];
    fetch("https://footage-media.com/json/cgfw.json")
      .then((response) => response.json())
      .then((cgfwData) => {
        arry.push(cgfwData);
        this.setState({ dataSource: arry });
      });
  }

  getSetInfo(num, dataSourceIndex) {
    console.log(this.state.dataSource);
    let i = 0,
      index = dataSourceIndex;

    if (this.state.dataSource[index] === undefined) return false;
    while (
      this.state.dataSource[index][i] &&
      this.state.dataSource[index][i].Public_Set != num
    ) {
      i++;
    }
    if (this.state.dataSource[index][i] === undefined) {
      console.log("looking through next data set");
      index += 1;
      return this.getSetInfo(num, index);
    } else {
      return this.state.dataSource[index][i];
    }
  }

  changeState = (num) => {
    this.setState({ setData: this.getSetInfo(num, 0) });
  };

  render() {
    const TRACKING_ID = "G-DM4W28MF1G ";
    //const TRACKING_ID = 'UA-43768608-2'
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(document.location.pathname);

    let SetNum = parseInt(window.location.href.split("=")[1]),
      setData = this.getSetInfo(SetNum, 0);

    if (setData) {
      return (
        <Fragment>
          <NaviContainer></NaviContainer>
          <Card
            bg="black"
            text="light"
            style={{ width: "85%" }}
            className="test"
          >
            <Videoscreen
              title={setData.Product_Name}
              jw_link={setData.jw_link}
              durration={setData.Clip_Length}
              post_date={setData.Date_}
            ></Videoscreen>
            <h1 className="bg-dark-red tc">
              Other Videos Featuring {setData.Model}
            </h1>
            <Featureing
              model={setData.Model}
              setNumber={setData.Public_Set}
              changeState={this.changeState}
              mediaType="video"
              site={setData.Site}
              title={setData.Product_Name}
              durration={setData.Clip_Length}
              post_date={setData.Date_}
            ></Featureing>
          </Card>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <p>Loading...</p>
        </Fragment>
      );
    }
  }
}

export default PublicSet;
