import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

// Pages for routing
import Main from "./Pages/Main";
import PublicSet from "./Pages/PublicSet";
import Four04 from "./Pages/Four04";
import Roster from "./Pages/Roster";
import Faq from "./Pages/Faq";
import Set from "./Members/Pages/Set";
import Page from "./Pages/Page";
import MembersArea from "./Members/MembersArea";
import MainMem from "./Members/Pages/MainMem";
import MemRoster from "./Members/Pages/MembersRoster";
import MemPage from "./Members/Pages/Page";

// Routing components
import { Route, Routes } from "react-router-dom";

// Google Analytics
//import ReactGA from 'react-ga4';
import ReactGA from "react-ga";

class App extends Component {
  render() {
    const TRACKING_ID = "G-DM4W28MF1G ";
    //const TRACKING_ID = 'UA-43768608-2'
    ReactGA.initialize(TRACKING_ID);
    ReactGA.pageview(document.location.pathname);

    return (
      <div>
        <Routes>
          <Route path="/" element={<Main></Main>}></Route>
          <Route path="/main:setNumber" element={<Main></Main>}></Route>
          <Route
            path="/members_area"
            element={<MembersArea></MembersArea>}
          ></Route>
          <Route path="/members_area/" element={<MainMem></MainMem>}></Route>
          <Route path="/members_area/:setNumber" element={<Set></Set>}></Route>
          <Route
            path="/members_area/mempage/:pageNumber"
            element={<MemPage></MemPage>}
          ></Route>
          <Route
            path="/members_area/memroster"
            element={<MemRoster></MemRoster>}
          ></Route>
          <Route
            path="/publicset/:setNumber"
            element={<PublicSet></PublicSet>}
          ></Route>
          <Route path="/page/:setNumber" element={<Page></Page>}></Route>
          <Route path="/faq" element={<Faq></Faq>}></Route>
          <Route path="/roster" element={<Roster></Roster>}></Route>
          <Route path="*" element={<Four04></Four04>}></Route>
        </Routes>
      </div>
    );
  }
}
export default App;
